<template>
  <div class="page news-preview flex-center flex-column position-relative">
    <div class="d-flex align-items-center">
      <div class="news-preview-inner flex-center mobile" v-if="isMobile">
        <div class="scroll-hider">
          <iframe
            class="iframe-elonezet mobile"
            :src="iframeSrc"
            frameborder="0"
          ></iframe>
        </div>
        <k-button
          icon="fas fa-desktop"
          class="preview-switch-btn"
          variant="outline-secondary"
          @click="previewType = PreviewTypeEnum.Desktop"
          v-b-tooltip.left="'Desktop nézet'"
        ></k-button>
      </div>
      <div class="news-preview-inner flex-center desktop mt-5" v-else>
        <iframe
          class="iframe-elonezet desktop"
          :src="iframeSrc"
          frameborder="0"
        ></iframe>
        <k-button
          icon="fa-mobile-alt"
          class="preview-switch-btn "
          variant="outline-secondary"
          v-b-tooltip.left="'Mobil nézet'"
          @click="previewType = PreviewTypeEnum.Mobil"
        ></k-button>
      </div>
      <div class="fixed-actions flex-center flex-column">
        <k-button
          v-if="!isMobile"
          class="open-project-btn font-size-sm mb-2"
          :style="{ background: this.user.Projekt.Hatterszin2 }"
          :href="iframeSrc"
          target="_blank"
        >
          Megnyitás új ablakban
        </k-button>
        <div
          class="qr-code-wrapper"
          :style="{ background: this.user.Projekt.Hatterszin2 }"
        >
          <h4 class="text-center font-size-sm mb-3">
            Megnyitás telefonon
          </h4>
          <k-qr-code-generator
            class="qr-code flex-center mb-3"
            :value="iframeSrc"
            :size="150"
          ></k-qr-code-generator>
          <p class="mb-0 text-center font-size-xs">
            Irányítsa ide telefonja kameráját...
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import settings from '../settings';
import { AppStoreTypes } from '../store/modules/app';
import { getToken } from '../utils/token';

const PreviewTypeEnum = Object.freeze({
  Mobil: 1,
  Desktop: 2,
});

export default {
  name: 'NewsPreview',
  data() {
    return {
      token: getToken(),
      previewType: PreviewTypeEnum.Desktop,
      PreviewTypeEnum,
    };
  },
  mounted() {},
  created() {},
  methods: {},
  computed: {
    ...mapGetters({ user: AppStoreTypes.user.get }),
    iframeSrc() {
      return `${settings.prodBaseUrl}#/?bottomBar=true&token=${this.token}`;
    },
    isMobile() {
      return this.previewType == PreviewTypeEnum.Mobil;
    },
  },
  watch: {},
  components: {},
};
</script>
<style lang="scss" scoped>
.news-preview::v-deep {
  .news-preview-inner {
    &.mobile {
      border: 3px solid #999;
      width: 400px;
      height: 730px;
      background: #222;
      border-radius: 20px;
    }
    &.desktop {
      border-radius: 6px;
      max-width: 1200px;
      background: #fff;
      padding: 10px 10px 0 10px;
      margin: 0 10px;
    }
    .scroll-hider {
      background: linear-gradient(
        rgba(251, 237, 253, 1) 0%,
        rgba(207, 195, 211, 1) 100%
      );
      width: 370px;
      height: 700px;
      border-radius: 15px;
    }
  }
  .iframe-elonezet {
    border: 0;
    padding: 0;
    margin: 0;

    &.mobile {
      border-radius: 15px;
      width: 370px;
      height: 700px;
    }
    &.desktop {
      border-radius: 6px;
      width: 1200px;
      height: calc(100vh - 160px);
    }
  }
  .fixed-actions {
    position: fixed;
    right: 20px;
    bottom: 20px;
    max-width: 210px;

    .qr-code-wrapper {
      border-radius: 20px;
      padding: 20px;

      .qr-code {
        border-radius: 15px;
        padding: 10px;
        background: white;
      }
    }
  }
  .open-project-btn {
    border: none;
    border-radius: 20px;
    padding: 10px;
    color: #343a40;
    width: 100%;
  }
  .preview-switch-btn {
    position: absolute;
    top: 0;
    right: 20px;
  }
}
</style>
